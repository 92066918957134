<template>
  <b-overlay :show="loading">
    <form-filter-export @onExport="exportCollection" :type_export="['excel', 'pdf']">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales (opsional)" class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group label="Konsumen (opsional)">
          <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
    </form-filter-export>
    <b-card>
      <b-row>
        <b-col cols="12">
          <h2 class="text-danger text-right">
            Total Piutang: <strong>Rp {{ formatRupiah(totalCollection) }}</strong>
          </h2>
        </b-col>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
  
        <b-col cols="12">
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(sales)="{ item }">
              {{ item.sales ? item.sales.nama_lengkap : "-" }}
            </template>
            <template #cell(konsumen)="{ item }">
              {{ item.konsumen ? item.konsumen.nama_toko : "-" }}
            </template>
            <template #cell(tanggal)="{ item }">
                {{humanDate(item.tanggal)}}
              </template>
            <template #cell(status)="{ item }">
              <b-badge v-if="item.status == 1" variant="success">LUNAS</b-badge>
              <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
            </template>
            <template #cell(bayar)="{ item }">
              <b-badge v-if="item.bayar == 1" variant="success">SUDAH BAYAR</b-badge>
              <b-badge v-else variant="danger">BELUM BAYAR</b-badge>
            </template>
            <template #cell(tagihan)="{ item }">
              <strong
                >Rp
                {{
                  formatRupiah(item.total_bayar - item.total_pembayaran)
                }}</strong
              >
            </template>
  
            <template #cell(action)="{ item }">
              <b-button
                size="sm"
                variant="warning"
                @click.prevent="$router.push(`/penjualan/detail/${item.id}`)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- <b-button
                v-if="item.status == 0 && allowUpdate()"
                size="sm"
                variant="outline-warning"
                @click.prevent="$router.push(`/penjualan/edit/${item.id}`)"
              >
                <feather-icon icon="EditIcon" />
              </b-button> -->
            </template>
          </b-table>
        </b-col>
  
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from 'vue-select'
import {
  BTable,
  BOverlay,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BFormCheckbox,
} from "bootstrap-vue";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    vSelect,
    FormFilterExport,
    BCardActions,
    ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      showModalEdit: false,
      showModalTambah: false,
      id: null,
      form: {
        blok: null,
        gudang_id: null,
      },
      konsumenOptions: [],
      salesOptions: [],
      dataExport: {
        tgl_awal: null,
        tgl_akhir: null,
        sales_id: null,
        konsumen_id: null
      },
      dataGudang: [],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "no_kwitansi", label: "No. Nota", sortable: true },
        { key: "konsumen.type_konsumen.nama", label: "Jenis Konsumen" },
        { key: "konsumen", label: "Konsumen", sortable: true },
        { key: "sales", label: "Sales", sortable: true },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status", label: "Status Transaksi" },
        { key: "bayar", label: "Status Pembayaran" },
        { key: "tagihan", label: "Sisa Tagihan" },
        { key: "action", label: "#" },
      ],
      items: [],
      swalOptions: {
        title: "Pindahkan Stok Retur ke Mobil?",
        text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Retur Barang",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    totalCollection() {
      return this.items.reduce((total, item) => total += (item.total_bayar - item.total_pembayaran), 0)
    }
  },
  methods: {
    async exportCollection({tgl_awal, tgl_akhir, type_export}) {
      if(!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export
      }
      if(this.dataExport.konsumen_id) params.konsumen_id = this.dataExport.konsumen_id
      if(this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id

      try {
        this.loading = true
        const response = await this.printCollection(params)
        this.loading = false
        if(type_export == 'pdf') {
          window.open(URL.createObjectURL(response))
        }
        else {
          await window.open(response)
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split('/')
            const filename = arrFile[ arrFile.length - 1 ]

            await this.clearExportedFile(filename)
          }, 1000)
        }
      }
      catch(e) {
        console.error(e)
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    async getData() {
      const params = this.isSales
        ? { order: "desc", id_sales: this.user.karyawan.id,status:0 }
        : { order: "desc", status:0 };
      this.items = await this.$store.dispatch("penjualan/getData", params); // belum difilter per sales
      this.items.map((penjualan) => {
        penjualan.checked = false
        penjualan.total_utang = penjualan.total_bayar - penjualan.diskon - penjualan.total_pembayaran
      });
      this.totalRows = this.items.length;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getKonsumen() {
      const params = {
        order: 'desc'
      }
      if(this.isSales) {
        params.sales_id = this.user.id
        params.status = 3 // disetujui
      } 
      else {
        params.status = 3
      }

      const konsumens = await this.$store.dispatch('ajuankonsumen/getData', params)
      this.konsumenOptions = konsumens.map(item => ({value: item.id, text: item.nama_owner}))
    },
    async getSales() {
      const levels = await this.$store.dispatch('level/getData', {
          search: 'sales'
      })
      const salesLevel = levels.find(level => this.getShortName(level.nama_level) == 'sales')
      const params = {}
      if(salesLevel) params.level_id = salesLevel.id

      const sales = await this.$store.dispatch('pengguna/getData', params)
      this.salesOptions = sales.map(item => ({
          value: item.karyawan.id,
          text: item.karyawan.nama_lengkap
      }))
    },
  },
  created() {
    this.getKonsumen()
    if (this.isSales) {
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        { key: "no_kwitansi", label: "No. Nota", sortable: true },
        { key: "konsumen.type_konsumen.nama", label: "Jenis Konsumen" },
        { key: "konsumen", label: "Konsumen", sortable: true },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status", label: "Status Transaksi" },
        { key: "bayar", label: "Status Pembayaran" },
        { key: "tagihan", label: "Sisa Tagihan" },
        { key: "action", label: "#" },
      ];
    } else {
      this.getSales()
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        { key: "no_kwitansi", label: "No. Nota", sortable: true },
        { key: "konsumen.type_konsumen.nama", label: "Jenis Konsumen" },
        { key: "konsumen", label: "Konsumen", sortable: true },
        { key: "sales", label: "Sales", sortable: true },
        { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status", label: "Status Transaksi" },
        { key: "bayar", label: "Status Pembayaran" },
        { key: "tagihan", label: "Sisa Tagihan" },
        { key: "action", label: "#" },
      ];
    }
    this.getData();
  },
};
</script>
